@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
::-webkit-scrollbar {
  display: none;
}
.load{
  width: 100%;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-backdrop {
  --bs-backdrop-bg: #202020;
}
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
/*Last Card Animation*/
.card {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content {
  z-index: 1;
  text-align: center;
}
/* .circle-content {
  z-index: 1;
  text-align: center;
  padding: 20px;
} */

.logo, .name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s ease;
}

.logo {
  right: -100%;
}

.name {
  left: -100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.card:hover .logo {
  right: 20px;
  opacity: 1;
}

.card:hover .name {
  left: 20px;
  opacity: 1;
}

.card:hover .overlay {
  opacity: 1;
}






.clear-fix:after {
  display: block;
  clear: both;
  content: "";
}

.img-responsive {
  
  max-width: 100%;
  height: auto;
}
.cardd-img{
  /* width: 42%;
  margin: -50px;
  position: relative; */
}

.card__collection {
  position: relative;
  display: block;
  /* background: rgba(10, 10, 10, 0.5); */
  /* padding: 10px; */
  font-family: "Do Hyeon", sans-serif;
  overflow: hidden;
}
.card__collection .cards {
  /* width: 300px;
  height: 400px; */
  display: block;
  background-size: cover;
  float: left;
  border-radius: 0px;
  position: relative;
  overflow: hidden;
  background-position: center;
  /* margin: 10px; */
}
.card__collection .cards--one {
  backface-visiblility: hidden;
}
.card__collection .cards--one:hover:after {
  bottom: -20px;
}
.card__collection .cards--one:hover:before {
  bottom: -10px;
}
.card__collection .cards--one:hover .cards--one__rect {
  left: 45%;
}
.card__collection .cards--one:hover .cards--one__rect--back {
  left: 50%;
}
.card__collection .cards--one::after {
  content: "";
  display: block;
  position: absolute;
  height: 70px;
  transform: rotate(-3deg);
  background: #e660e3;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: -10px;
  z-index: 9;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}
.card__collection .cards--one:before {
  content: "";
  display: block;
  position: absolute;
  height: 80px;
  transform: rotate(-3deg);
  bottom: -90px;
  left: 0;
  background: #fff;
  right: -10px;
  z-index: 5;
  transition: all 0.2s ease-in;
  transition-delay: 0.1s;
}
.card__collection .cards--one__rect {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  background: #0f9bc0;
  width: 126px;
  height: 55px;
  transform: skewY(5deg);
  position: absolute;
  display: block;
  top: 60%;
  left: -45%;
  z-index: 1;
  line-height: 3.3rem;
  text-align: center;
  transition: all 0.2s ease-in;
}
.card__collection .cards--one__rect--back {
  display: block;
  background: rgba(34, 65, 154, 0.8);
  width: 126px;
  height: 55px;
  transform: skewY(7deg);
  position: absolute;
  top: 65%;
  left: -50%;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}
.card__collection .cards--one__rect p {
  transform: skewY(-7deg);
  position: relative;
}
.card__collection .cards--two {
  position: relative;
  backface-visibility: hidden;
}
.card__collection .cards--two p {
  position: absolute;
  top: 78%;
  left: -100%;
  padding-left: 15px;
  text-transform: capitalize;
  color: #fff;
  font-size: 22px;
  font-family: "poppins";
  font-weight: 500;
  line-height: 20px;
  z-index: 8;
  transition: all 0.6s ease;
}
.p-code p{
  margin-left: 0px!important;
}
.card__collection .cards--two h6{
  position: absolute;
  padding-left: 15px;

  top: 86%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 15px;
  font-family: "poppins";
  font-weight: 400;

  z-index: 8;
  transition: all 0.6s ease;
}

.crad-img img{
  width: 100%!important;
}
.card__collection .cards--two .crad-img {
  position: absolute;
  top: 59%;
  right: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 20px;
  z-index: 8;
  transition: all 0.6s ease;
}
.card__collection .cards--two:hover p{
  left: 2%;
}

.card__collection .cards--two:hover h6{
  left: 2%;
}

.card__collection .cards--two:hover .crad-img{
  right: 6%;
}

.card__collection .cards--two:hover .cards--two__rect {
  top: 70%;
}
.card__collection .cards--two:hover .cards--two__rect:before {
  transform: translateY(15px);
}
.card__collection .cards--two:hover li {
  transform: translateY(0);
}

.card__collection .cards--two img {
  transition: all 0.2s ease;
}
.card__collection .cards--two__tri {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #fff;
  opacity: 0.9;
  position: absolute;
  display: block;
  top: 0;
  right: -100%;
  transition: all 0.3s ease-in-out;
}
.card__collection .cards--two__tri:before {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #57ccfd;
  position: absolute;
  content: "";
  display: block;
  top: -220px;
  right: -612px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}
.card__collection .cards--two__rect {
  width: 750px;
  height: 200px;
  /* filter: blur(4px); */
  backdrop-filter: blur(10px);
  display: block;
  position: absolute;
  top: 175%;
  left: -78%;
  transform: rotate(181deg);
  z-index: 5;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}
.card__collection .cards--two__rect:before {
  content: "";
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  
  transform: translateY(200px);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.card__collection .cards--two ul {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 9;
}
.card__collection .cards--two ul li {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  transform: translateY(100px);
}
/*Download plan Animation*/
/* Basic Styles */
.fancy-button {
  position: relative;
  color: #fff;
  border: 1px solid;
  margin: 5px;
  text-decoration: none;
  border: 0px;
  display: block;
  outline: none;
  line-height: 100%;
  overflow: hidden;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.fancy-button:hover {
  text-decoration: none;
}
.fancy-button .icon {
  float: left;
}
.fancy-button.icon{
display: inline-block;
}
.fancy-button:active .icon,
.fancy-button:focus .icon {
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
}

.down {
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.down:hover .icon {
  -webkit-animation: down 0.75s forwards linear;
  -moz-animation: down 0.75s forwards linear;
  -o-animation: down 0.75s forwards linear;
  animation: down 0.75s forwards linear;
}

@-webkit-keyframes down {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 12px;
  }
  50% {
    margin-top: 24px;
  }
  75% {
    margin-top: 48px;
  }
  76% {
    opacity: 0;
    margin-top: -48px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-moz-keyframes down {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 12px;
  }
  50% {
    margin-top: 24px;
  }
  75% {
    margin-top: 48px;
  }
  76% {
    opacity: 0;
    margin-top: -48px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-o-keyframes down {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 12px;
  }
  50% {
    margin-top: 24px;
  }
  75% {
    margin-top: 48px;
  }
  76% {
    opacity: 0;
    margin-top: -48px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes down {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 12px;
  }
  50% {
    margin-top: 24px;
  }
  75% {
    margin-top: 48px;
  }
  76% {
    opacity: 0;
    margin-top: -48px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}


.buttonnew1 {
  background-color: #fff; /* Initial background color */
  transition: background-color 0.3s ease-in-out; /* Smooth transition effect with ease-in-out */

}


.buttonnew1:hover {
  transition-delay: 0.2s; /* Adjust the delay time as needed */

  background-color: #ffcca7; /* Initial background color */
}


.buttonsvg:hover + .buttonnew1 {
  background-color: #ffcca7;
}

.buttonsvg :hover ~ .buttonnew1 {
  transition-delay: 0.2s; /* Adjust the delay time as needed */
  background-color: #000;
}

/*Animation Arrow*/
.orange-col{
  border: 1px solid white;
}
.arrow-div {
  position: relative;
  animation: moveUpDown 4s infinite; /* 2s for up and 2s for down, repeat infinitely */
}

@keyframes moveUpDown {
  0%, 100%{
      top: 91% /* Start and end position */
  }
  50% {
      top: 88%; /* Middle position */
  }
  100%{
      top: 91%;
  }
}

.rose{
background:#FF66CC;
}

.center{
  position: absolute;
  bottom: 2px;
  left: 35px
}

.circle{
width: 35px;
height: 35px;
border-radius: 50%;
box-shadow: 0px 0px 1px 1px #fff;
}

.pulse {
animation: pulse-animation 2s infinite!important;
}

@keyframes pulse-animation {
0% {
  box-shadow: 0 0 0 0px #ffccA7;
}
100% {
  box-shadow: 0 0 0 20px #ffccA7;
}
}
.vibrating-img {
  /* Define initial styles */
  transform: rotate(0deg);
}

.vibrating-img.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: vibrate 0.5s!important;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite!important;
}

@keyframes vibrate {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-1deg); }
  20% { transform: rotate(1deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(1deg); }
  50% { transform: rotate(-1deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(-1deg); }
  80% { transform: rotate(1deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-1deg); }
}



.container-ani {
  position: relative;
  white-space: nowrap;
  /* width: 150px; */
  height: 32px;
  background-color: transparent;
}
/*Animation header*/

/* Style for the line */
.line {
  width: 100%; /* Adjust as needed */
  height: 1px; /* Adjust as needed */
  margin-bottom: 5px; /* Adjust as needed */
}

/* Style for the box */
.box {
  margin-top: -20px;
  width: 0px; /* Initial width */
  height: 0px; /* Initial height */
  background-color: #f0f0f042;
  margin-right: 5px; /* Adjust as needed */
  display: inline-block;
  position: absolute; /* Set position to absolute */
  bottom: 0; /* Align to the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  transition: width 0.3s ease, height 0.3s ease; /* Smooth transition */
  z-index: 1; /* Ensure box appears above content */
}

/* Animation for box when hovered over */
.nav-item:hover .box {
  animation: growBox 0.3s ease forwards, shrinkBox 1s ease forwards 0.9s;
}

/* Keyframes for the grow animation */
@keyframes growBox {
  from {
    width: 1px; /* Initial width */
    height:1px; /* Initial height */
  }
  to {
    width: 100%; /* Final width */
    height: 40px; /* Final height */
  }
}

/* Keyframes for the shrink animation */
@keyframes shrinkBox {
  from {
    width: 100%; /* Final width */
    height: 40px; /* Final height */
  }
  to {
    width: 1px; /* Initial width */
    height:1px; 
  }
}
/* 
.line {
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  width: 2px;
  height: 0;
  background-color: #cdcdcd29;
  transition: height 0.8s ease, width 0.8s ease;
}

.container-ani:hover .line {
  height: 100%;
  width: 100%;
  background-color: #ffffff3b;
  border-bottom: 1px solid #fff;
  margin-left: 35px;
}
.container-ani:hover .box {
  opacity: 1;
} */
/*4-Circle Animation*/
.circle-container1 {
  position: relative;
  width: 220px;
  height: 220px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}

.circle1 {
  position: absolute;
  width: 220px;
  height: 220px;
  background-image: url('./circle22.png');
  background-size: cover;
  border-radius: 50%;
  transition: transform 0.5s;
}

.circle-container1:hover .circle1 {
  animation: rotate 10s linear infinite;
}
.play-button{
  position: absolute;
  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
  transform: translate(-50%, -50%);
}
.content h6{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  font-family: "Poppins";
  opacity: 1;
  transition: opacity 0.3s;
}
span{
  white-space: nowrap;
}
.circle-container1:hover .content {
  opacity: 1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*Gradient*/
.grid {
	position: relative;
	margin: 0 auto;
	/* padding: 1em 0 4em; */
	max-width: 1000px;
	list-style: none;
	text-align: center;
}

/* Common style */
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	width: 100%;
	text-align: center;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/* Individual effects */



figure.effect-sadie figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(top, rgba(72,76,97,0) 0%, rgba(57, 90, 253, 0.8) 75%);
	background: linear-gradient(to bottom, rgba(19, 136, 15, 0) 0%, rgba( 0 0 0 /52%) 75%);
	content: '';
	opacity: 0;
	-webkit-transform: translate3d(0,50%,0);
	transform: translate3d(0,50%,0);
}

figure.effect-sadie h2 {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	color: #484c61;
	-webkit-transition: -webkit-transform 0.35s, color 0.35s;
	transition: transform 0.35s, color 0.35s;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  font-size: 25px;
  font-weight: 300!important;
  font-family: "poppins";
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	opacity: 0;
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}

figure.effect-sadie:hover h2 {
	color: #fff;
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
	transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

figure.effect-sadie:hover figcaption::before ,
figure.effect-sadie:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}



figure.effect-chico figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid #fff;
	content: '';
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-chico h2 {
	padding: 20% 0 20px 0;
}

figure.effect-chico p {
	margin: 0 auto;
	max-width: 200px;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

@media screen and (max-width: 50em) {
	.content {
		text-align: center;
	}
	.grid figure {
		display: inline-block;
		float: none;
		margin: 10px auto;
		width: 100%;
	}
}
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { display: table; content: ''; }
.clearfix:after { clear: both; }

body {
	background: #2f3238;
	color: #fff;
	font-weight: 400;
	font-size: 1em;
	font-family: 'Raleway', Arial, sans-serif;
}

a {
	outline: none;
	color: #3498db;
	text-decoration: none;
}

a:hover, a:focus {
	color: #528cb3;
}

/* section {
	padding: 1em;
	text-align: center;
} */

.content {
	margin: 0 auto;
	max-width: 1000px;
}

.content > h2 {
	clear: both;
	margin: 0;
	padding: 4em 1% 0;
	color: #484B54;
	font-weight: 800;
	font-size: 1.5em;
}

.content > h2:first-child {
	padding-top: 0em;
}

/* Header */
.codrops-header {
	margin: 0 auto;
	padding: 4em 1em;
	text-align: center;
}

.codrops-header h1 {
	margin: 0;
	font-weight: 800;
	font-size: 4em;
	line-height: 1.3;
}

.codrops-header h1 span {
	display: block;
	padding: 0 0 0.6em 0.1em;
	color: #74777b;
	font-weight: 300;
	font-size: 45%;
}

/* Demo links */
.codrops-demos {
	clear: both;
	padding: 1em 0 0;
	text-align: center;
}

.content + .codrops-demos {
	padding-top: 5em;
}

.codrops-demos a {
	display: inline-block;
	margin: 0 5px;
	padding: 1em 1.5em;
	text-transform: uppercase;
	font-weight: bold;
}

.codrops-demos a:hover,
.codrops-demos a:focus,
.codrops-demos a.current-demo {
	background: #3c414a;
	color: #fff;
}

















.about-stoketext::before{
  content: '';
    float: left;
    fill: rgba(253, 115, 60, 0.20);
    filter: blur(120px);
    height: 150px;
    width: 150px;
    background-color: #fa6a035e;
    /* float: right; */
    left: 18%;
    position: absolute;
    top: 300;

}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgb(0,0,0);
background: linear-gradient(193deg, rgba(0,0,0,0.22646282634147408) 0%, rgba(0,0,0,1)100%);
  transition: height 0.5s ease;
}
.background-about-stoketext{
  color: transparent; /* Set text color to transparent */
  text-align: center;
  font-family: 'Poppins';
  margin: auto;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 141.818% */
     color: transparent; /* Make text transparent */
     -webkit-text-stroke: 1.5px white; /* Add white border stroke */
     text-stroke: 1.5px white; /* Add white border stroke */
}

.background-about-stoketext::before{
  content: '';
    float: center;
    fill: rgba(253, 115, 60, 0.20);
    filter: blur(120px);
    height: 350px;
    width: 350px;
    background-color: #fa6a035e;
    float: right;
    left: 60%;
    position: absolute;
    margin-top: 130px;
}
.background-about-stoketext-second{
  color: transparent; /* Set text color to transparent */
  text-align: center;
  font-family: 'Poppins';
  margin: auto;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 141.818% */
     color: transparent; /* Make text transparent */
     -webkit-text-stroke: 1.5px white; /* Add white border stroke */
     text-stroke: 1.5px white; /* Add white border stroke */
}
.background-about-stoketext-second::before{
  content: '';
    float: center;
    fill: rgba(253, 115, 60, 0.20);
    filter: blur(120px);
    height: 350px;
    width: 350px;
    background-color: #fa6a035e;
    float: right;
    left: 20%;
    position: absolute;
    margin-top: 130px;
}
.image-container:hover .overlay {
  height: 80%; /* Moves overlay from bottom to top */
  background: rgb(0,0,0);
background: linear-gradient(193deg, rgba(0,0,0,0.22646282634147408) 0%, rgba(0,0,0,1)100%);
}
.hero-section{
  background-image: url('./bg.jpg');
  background-size: cover;
  height: 90vh;
}
.logo1{
  width: 70px;
}
.navbar-expand-lg{
  padding: 20px 0px 20px 50px;
}
.orange{
  color: #FF7F22; 
}
.highlight{
  color: #FF7F22;
  font-size:22px;
  font-family: 'Poppins';
font-style: normal;
position: relative;
}
/*Nav Animation*/
.nav-animation{
  position: relative;
  top: 2px;
  margin-top: 10px;
  height: 3px;
  width: 75px;
  background-color: #fff;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: #FFF;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 140% */
padding: 0px 30px;
}
.line-tag{
  width: 20%;
  height: 2vh;
  background-color: #000;
}
.nav-arrow{
  position: absolute;
  margin-left: 48px;
  bottom: -19px;

}
.banner-caption{
  position: absolute;
  bottom: 10%;
  padding: 2px 60px 80px 60px;
}
.banner-caption h5{

  position: relative;
  color: #FFF;
font-family: 'Poppins';
width: 40%;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: 59px; /* 111.321% */
text-transform: uppercase;
}

.bg-black{
  background-color: #202020;
}
.bg-black p{
  margin: 0px;

  font-size: 14px;
  line-height: 26px;
  font-family: "poppins";
  font-weight: 300;
}

.topsection::after{
  border-radius:294px;
background: rgba(253, 115, 60, 0.20);
filter: blur(110px);
}

.circle{
  left: 0%;
  top: 105%;
  position: absolute;
}
.round-circle{
  /* border-radius: 100px; */
  /* border: 2px dotted #fff; */
  
  margin: auto;
  height: 200px;
  width: 200px;
  border-radius: 50%; /* Makes the div circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  font-family: Arial, sans-serif; /* Font family */
  font-size: 16px; /* Font size */
  transition: border 2s ease; /* Smooth transition for the border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}
/* .round-circle:hover{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  animation: rotate 15s linear infinite;
  /* transition: border 2s ease; */
/* }  */
/* .round-circle2{
  border-radius: 100px;
  border: 2px dotted #fff;
  height: 240px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Arial, sans-serif; 
  font-size: 16px; 
} */
/* 
 .round-circle3{
  border-radius: 100px;
  border: 2px dotted #fff;
  height: 280px;
  width: 200px;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; 
  font-family: Arial, sans-serif;
  font-size: 16px; 
}  */


.orangetext{
  color: #FA6B03;
}

.round-circle h6{
  color: #FFF;
font-family: 'Poppins';
text-align: center;
font-size: 27px;
font-style: normal;
font-weight: 600;
line-height: 34px;
}
.reel-btn{
  width: 18%!important;
  position: absolute;
  top: 46%;
  left: 43%;
}

.sticky-box {
  position: fixed;
  bottom: 20px;
  right: 25px;
  padding: 10px 0px 0px 0px;
  color: #ffffff38;
  width: 340px;
  height: 165px;
    background: #ffffff38;
    z-index: 999;
    animation: pulse 2s infinite;
  backdrop-filter: blur(15px);
  font-family: 'poppins';
}

.img-container img{
  left: -32px;
  margin-top: -136px!important;
  width: 55%;
  position: relative;

}
.img-container-why img{
  left: -32px;
  margin-top: -136px!important;
  width: 27%;
  position: relative;
}
.img-container-one{
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container-one img{
  left: 34%;
  position: absolute;
  width: 10%;
  margin-top: 40px!important;
}


.labeltext{
  padding: 0px 20px;
  color: #FFF;
text-align: center;
font-family: 'Poppins';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 21px; /* 161.538% */
margin: 10px 0px;
}
.btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  font-size: 13px;
}
.btn-container button{
  border: none;
  border-right: 1px solid #FF7C1C;
  width: 30%;
  padding: 6px;
  font-weight: 400;
  transition: background-color 0.3s ease-in-out; /* Smooth transition effect with ease-in-out */
}
.orangebtn{
  background-color: #FF7C1C;
}
.btn-container button:active{
  background-color: #000;
}
.fieldRequired{
  float: left;
}
.fieldRequired p{
  font-size: 12px;
  font-family: "poppins";
  font-weight: 400;
}
.buttonnew{
  background-color: #FA6B03;
  color: #000;
  display: block;
text-align: center;
font-family: 'Poppins';
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 140% */
border-radius: 0px;
padding: 10px 30px!important;
border: 0px;
}
.buttonnew1{
  color: #000;
  display: block;
text-align: center;
font-family: 'Poppins';
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 140% */
border-radius: 0px;
padding: 10px 30px!important;
border: 0px;
}
.buttonsvg{
  color: #000;
  display: block;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 140% */
  border-radius: 0px;
  padding: 10px 15px!important;
  border: 0px;
  background-color: #FA6B03;
}




/* RangeSlider.css */
.slider-container h5{
  
  font-size: 14px;
  font-family: "Poppins";
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
}
.range-slider-container {
  position: relative;
  width: 100%;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 18px;
  border-radius: 10px;
background: linear-gradient(270deg, #FA6B03 14.92%, #FF8025 71.86%, #FF8025 121.89%);
  outline: none;
  transition: opacity 0.2s;
  border: 3px solid #fff;
  margin-top: 25px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FA6B03;
  border: 3px solid #FEA25F;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.slider-active-label {
  position: absolute;
  top: 3px;
  transform: translateX(-40%);
  color: #000;
  background-repeat: no-repeat;
  fill: #FFF;
  /* box-shadow: -2px -3px 4px 0px rgba(0, 0, 0, 0.26) inset; */
  padding: 8px 14px;
  background-image: url('./rect.svg');
  background-size: revert;
  /* border-radius: 5px; */
  font-size: 14px;
  display: flex;
  font-family: 'poppins';
  align-items: center;
  /* right: 0px; */
}
.topsection::before{
  position: absolute;
  content: '';
  background-image: url('./line.svg');
}
.circlegenerate{
position: absolute;
border-radius: 294px;
background: rgba(253, 115, 60, 0.20);
filter: blur(110px);
width: 300px;
height: 263px;
flex-shrink: 0;
left: -14px;
margin-top: 70px;

}

.videocard
{
  background: rgba(29, 29, 29, 0.00);
  padding: 20px;
  z-index: 2;
  position: relative;
  border-radius: 15px;
  border: 0.5px solid #fa6a0345;
  background: #ffffff2e;
  backdrop-filter: blur(10px);
}
.caption{
  margin-top: 20px;
}

.videotext
{
  color: #FFF;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
text-align: center;
font-weight: 400;
line-height: 51px; /* 212.5% */
text-transform: capitalize;
}
.stoketext{
  color: transparent; /* Set text color to transparent */
  text-align: center;
  font-family: 'Poppins';
  margin: 70px auto;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 78px; /* 141.818% */
     color: transparent; /* Make text transparent */
     -webkit-text-stroke: 1.5px white; /* Add white border stroke */
     text-stroke: 1.5px white; /* Add white border stroke */
}
.lastcard{
  display: flex;
  padding: 40px 0px 40px 0px;
  margin: 20px;
  width: 100%;
}
/*Footer Card*/
.btn-1d {
  overflow: hidden;
}
.btn-1 {
  color: #fff;
}
.btn-1d:hover span {
  color: #000;
  z-index: 9999;
  position: relative;
}

.btn {
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 400;
    position: relative;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
    background-color: #FA6B03;
    margin-top: 20px;
    align-items: center;
    color: #fff;
    padding: 15px 25px ;
    border: 0;
    border-radius: 0px;
    font-family: 'poppins';
    width: 59%;
}
.btn:after {
  content: '';
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;

}
.btn-1d:after {
    width: 0;
    height: 103%;
    top: 50%;
    left: 50%;
    background: #ffccA7;
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.btn-1d:hover,.btn:hover , .btn-1d::after{
    color: #000!important;
}
.btn-1d:hover:after {
    width: 100%;
    opacity: 1;
}
.hndle{
  margin-top: 0;
}
.buttonnew1{
  border-left: 1px solid #000;
}
.buttonnew1:hover{
  color: #000!important;
}
.buttonnew1:hover span{
  color: #000!important;
}
/*
.buttonnew1:hover:after{
    width: 100%;
    opacity: 1;
}
.buttonnew1:after{
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background: #fec49a;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
} */








.footer-line{
  /* color: #FA6B03; */
  border-bottom: 1px solid #FA6B03;
}
.footer-card{
  width: 100%;
  background-color: #000;
}
.footer-card h1{
  font-size: 40px;
  font-weight: 500;
  font-family: "poppins";
  line-height: 48px;
  color: #fff;
}
.footer-card .p{
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 300;
  font-family: "poppins";
  line-height: 26px;
  color: #fff;
}

.card .card-text{
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 24px;
  font-family: "poppins";
  color: #000;
}
.card-body h1{
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  font-family: "poppins";
  color: #000;
}
.card-body h6{
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  font-family: "poppins";
  line-height: 21px;
  color: #000;
}
.card-body p{
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  font-family: "poppins";
  color: #000;
}

.footer{
  width: 100%;
  background-color: #FA6B03;
  padding: 15px;
  z-index: -1;
}
.footer h6{
  margin-top: 3px;
  font-size: 15px;
  font-weight: 400;
  font-family: "poppins";
  line-height: 21px;
}
.reel-img{
  border-radius: 10px;
}

.orange-bg1 {
background-color: #272727;
}
.separate{
  height: 290px;
  background-color:#272727 ;
  margin-top: -20%;
}


.reel-cards{
  position: relative;
}
.reel-play{
  position: absolute;
  right: 37%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/*Orange section*/
.orange-banner{
  padding-left: 25px;
  display: flex;
}
.img-orange{
  /* position: relative;
  display: inline-block; */
  margin: 20px;
}
.img-orange img{
  width: 100%;
}
.move-future{
  margin-top: 20px;
}
.move-future h1{
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 500;
  text-align: center;
}
.madhur-circle{
  margin-top: 50px;
  border-radius: 100px;
  height: 180px;
  width: 180px;
  border-radius: 50%; /* Makes the div circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  background-color: white;
  z-index: 0;
  animation: pulse-animation 2s infinite;

}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 204, 167, 0.2)
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 204, 167, 0.2)
  }
}




.madhur-circle .madhur-text{
  color: #FA6B03;
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
}
.behind-madhur{
  margin-top: 150px;
  position: absolute;
  width: 35%;
  border: 1px dotted white;
}
/* .img-div{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
} */
.orange-bg{
  background-color: #202020;
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
}
.bg-black{
  background-color: #202020!important;
}
.orange-cont{
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.orange-bg h1{
  color: #fff;
  font-size: 38px;
  font-weight: "600";
  font-family: "Poppins";
  text-align: left;
  line-height: 46px;
  margin-bottom: 0px;
  margin-left: 24px;

}
.btn-container button:hover{
  background-color: #ffccA7;
}
.gradient-circle{
  width: 200px;
  height: 200px;
  position: absolute;
  left: 12%;
  margin-top: -100px;
  background-color: rgb(63, 18, 0);
  border-radius: 50%;
}
.orange-bg p{
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-family: "poppins";
  text-align: left;
  margin-left: 15px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 0px 0px;
}

/* label {
  padding: 5px;
  width: 8%;
  background-color: #FA6B03;
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  font-family: "poppins";
} */
.form-label{
  width: 50%;
  margin: 10px;
}
.label1{
    background-color: white;
    color: black;
    margin-bottom: 5px;
    font-weight: 400;
    text-align: left;
  font-family: "poppins";
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
  width: 120px;
  text-align: center;
}

.label2{
    background-color: white;
    color: black;
    margin-bottom: 5px;
    font-weight: 400;
    text-align: left;
    position: absolute;
  font-family: "poppins";
  margin-top: 8px;
  margin-left: 10px;
  width: 120px;
  text-align: center;

}
.label3{
    background-color: white;
    color: black;
    margin-bottom: 5px;
    font-weight: 400;
    text-align: left;
  font-family: "poppins";

}
.resend {
  float: right;
  font-family: "poppins";
  color: #FA6B03;
  
}

.form-label input[type="text"],
input[type="email"], 
input[type="tel"] {
  background-color: #fff;
  padding: 15px;
  width: 100%;
  margin: 20px 5px 0px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: "poppins";
}
.modal-enter b{
  font-weight: 400;
  color: #999999;
}
.otpinput input{
  width: 40px!important;
  height: 40px;
  border-radius: 5px;
  margin: 8px;
  font-family: "Poppins";
  color: #000;
  background-color: #FFEBDC;
  border: 2px solid #fec49a!important;
  border: 0px;
  pointer-events: none;
}

.otpinput1 input{
  width: 40px!important;
  height: 40px;
  border-radius: 5px;
  margin: 8px;
  font-family: "Poppins";
  color: #000;
  background-color: #FFEBDC;
  border: 2px solid #FA6B03!important;
  border: 0px;
}
.otpinput1 input:focus{
  border: none;
}
.subbtn2{
  background-color: transparent;
  position: relative;
  margin-top: -55px;
  padding: 12px 30px;
  color: #FA6B03;
  border: 2px solid white;
  border-radius: 25px;
  cursor: pointer;
  font-family: "poppins";
  font-weight: 500;
  z-index: 0;
}
.subbtn1{
  z-index: 1;
  margin-top: 60px;
  padding: 12px 30px;
  background-color: #fff;
  color: #FA6B03;
  border: 1px solid white;
  border-radius: 25px;
  cursor: pointer;
  font-family: "poppins";
  font-weight: 500;

} 
.or-sec{
  margin-right: 50px;
  width: 400px;
  height: 348px;
  background-color: #ECE64F;
  border-radius: 12px;
}
/*About Section*/
.about-desc{
  display: flex;
  flex-direction: column;
  background-color: #202020;

}
.about-desc h1{
  text-align: left;
  color: #fff;
  font-size: 55px;
  font-family: "poppins";
  font-weight: 600;
  line-height: 78px;
}
.about-desc p{
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-family: "poppins";
  font-weight: 300;
  line-height: 28px;
}
.video-div{
  margin-top: 25px;
  width: 95%;
}
.reel-video{
  width: 85%;
}
.reel-video1{
  width: 100%;
}

video {
  width: 100%;
  margin: auto;
  display: block;
  border-radius: 10px;
}
.about-stoketext{
  color: transparent; /* Set text color to transparent */
  text-align: left;
  font-family: 'Poppins';
  margin: 120px auto;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 141.818% */
     color: transparent; /* Make text transparent */
     -webkit-text-stroke: 1px white; /* Add white border stroke */
     text-stroke: 1px white; /* Add white border stroke */
}
.owl-dots{
  display: none;
}
.about-stoketext1{
  color: #fff; /* Set text color to transparent */
  text-align: left;
  font-family: 'Poppins';
  margin: 90px 0px 100px 0px;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 141.818% */
     color: #fff    /* Add white border stroke */
}

.circleorange{
  height: 100px;
  width: 100px;
  background-color: #FA6B03;
  border-radius: 100px;
  position: absolute;
  margin-top: -36px;
    margin-left: -22px;
}

.circleorange1{
  height: 100px;
  width: 100px;
  background-color: #FA6B03;
  border-radius: 100px;
  position: absolute;
  float: right;
  right: 74px;
  z-index: 1;
  margin-top: -65px;
}

.white-line{
  width: 55%;
  margin-right: 65px;
  float: right;
  margin-top: -12px;
}
/* Media query for responsiveness */
@media (max-width: 768px) {
  .sticky-box {
      display: block; /* Hide the sticky box on smaller screens */

  }
}


.modal .modal-dialog1{
  margin: 50px auto;
  width: 25%!important;
}


.about-stoketext .per {
  background-image:url('./1per.png');
    position: static;
    width: 60%;

    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    margin-top: -78px;
    margin-left: -12px;
    
    z-index: 0;
}

.about-stoketext1 .per1 {
  background:url('./house2.png  ');
    position: static;
    width: 60%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    margin-top: -61px;
    z-index: 0;
}
.about-stoketext .per1{
  background:url('./house2.png  ');
    position: static;
    width: 60%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    margin-top: -61px;
    z-index: 0;
}



.M {
  font-size: 40vmin;
  color: #ac6b4d;
  font-family: 'Chonburi', cursive;
}

#circ {
  font-size: 3vmin;
  color: #000;
  font-family: 'Cousine', monospace;
  position: relative;
  margin-top: -10vmin;
  left: 1vmin;
}
.footerleft h5{
  font-size: 55px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #fff;


}
.fa{
  font-size: 25px;

}

footer{
  padding: 50px;
  background-image: url('./footerimg.jpg');
  background-size: cover;
    background-repeat: no-repeat;
}
.footerleft h6{
  font-size: 51px;
  font-weight: 200;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  color: #fff;


}
.rigthul{
  display: flex;
  color: #fff;
  list-style-type: none;
  padding: 0px;
}
.rigthul li{
color: '#fff';
font-family: "Poppins";
margin-left: 36px;
margin-bottom: 30px;
font-size: 17px;
}
.rigthul1{
  display: flex;
  color: #fff;
  list-style-type: none;
  padding: 0px;
}
.rigthul1 li{
color: '#fff';
font-family: "Poppins";
margin-left: 36px;
margin-bottom: 30px;
font-size: 17px;
}

.copyright p{
  margin-left: 30px;
  font-family: 'poppins';
}

.newletter input {
  outline: none;
  color: #fff;
  border-radius: 0;
  background-color: transparent;
  border: none;
  letter-spacing: 20px;
  font-family: 'poppins';
  padding: 15px 25px;
  font-size: 18px;
  letter-spacing: 3px;
  width: 59%; 
  border: 1px dashed #fff;
  transition: 0.3s ease-in-out;
}
.newletter input:hover{
  border: 0.8px solid #fff;
}
.reel-cont{
  /* margin-bottom: 40px; */
  /* padding-left: 25px; */
  padding: 0px 0px 0px 45px;
}
.newletter form{
  align-items: start;
}
/* .newletter  button{
  background-color: #FA6B03;
  margin-top: 20px;
  align-items: center;
  color: #fff;
  padding: 8px 25px!important;
  border: 0;
  font-family: 'poppins';
  width: 59%;
} */





.spacer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 3rem;
  color: #000;
}
.section {
  height: auto;
  width: 100%;
  position: relative;
  padding: 0;
  overflow-x: hidden;
}
.portfolio {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
	background-color: #1F242D;
  overflow: hidden;
}
.portfolio_title {
	position: absolute;
	top: 0;
	left: -15rem;
	font-size: 24rem;
	letter-spacing: 0;
	-webkit-text-stroke-color: #343A42;
	display: inline-block;
}
.text-stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #343A42;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  aling-content: center;
  justify-content: center;
  overflow: hidden;
}
.panel {
  display: flex;
  flex: 0 0 50%;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
	height: 100%;
  padding: 10rem 7rem 2rem 7rem;
  background-color: transparent;
  overflow: hidden;
}
.panel_item {
	height: 100%;
	width: 100%;
  margin: 0 auto;
}
.panel_img {
  width: 100%;
	height: 100%;
  margin: 0 auto;
}
.bg{
  background-color: red;
}
.circlelogo {
  background-image: url("../src/whitenothover.png");
  background-repeat: no-repeat;
  width: 211px;
  height: 211px;
  margin-left: 35px;
  margin-top: -245px;
  cursor: pointer; /* Change cursor on hover */
  z-index: 999999;
  position: relative;
  transition: background-image 0.3s ease-in-out;
  background-size: cover;
}

.circlelogo:hover {
  background-image: url("../src/madhur_circle.png");
}


.container1 {
  position: relative;
  width: 300px; /* Adjust according to your requirements */
  height: 300px; /* Adjust according to your requirements */
}

.circles {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 5px solid #ccc; /* Adjust border properties as needed */
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; /* Adjust size of the ring */
  height: 200px; /* Adjust size of the ring */
  border: 5px solid blue; /* Adjust border properties as needed */
  border-radius: 50%;
  animation: rotateRing 5s linear infinite; /* Adjust animation duration and timing function as needed */
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: blue; /* Adjust text color as needed */
  white-space: nowrap; /* Prevent the text from wrapping */
  animation: spinText 5s linear infinite; /* Adjust animation duration and timing function as needed */
}

@keyframes rotateRing {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinText {
  0% {
    transform: rotate(0deg) translateX(100px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(100px) rotate(-360deg);
  }
}

.dream-logo{
  margin-bottom: 30px;
  width: 300px;
  height: 300px;
  background-color: transparent;
  border-radius: 100%;
  transition: 1s ease-in-out;
}

  



.circular-image {
  width: 280px; /* Adjust as per your image size */
  height: 280px; /* Adjust as per your image size */
  border-radius: 50%;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bgback{
  fill: rgba(253, 115, 60, 0.20);
filter: blur(120px);
height: 200px;
width: 200px;
background-color: #fa6a035e;
float: right;
left: 0;
position: absolute;
top:300;
}


.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: yellow;
}

.lastContainer {
  display: flex;
  height: 100vh;
  background: yellow;
}
.modal-content{
  border-radius: 0px;
}
.rigthul li a {
  position: relative;
  color: #fff;
  text-decoration: none;
}

.rigthul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0;
  background-color: #fff;
  transition: width 0.3s ease;
}

.rigthul li a:hover::after {
  width: 100%;
}


.Toastify__toast-container .Toastify__close-button
{
  display: none;

}

.Toastify__toast-container div{
  font-family: "poppins";
}



@media only screen and (max-width: 768px) {
  .vibrating-img{
    z-index: 99999999;
  }
  .slider-container h5{
    margin-bottom: 15px;
  }
  .buttonsvg{
    padding: 0px 15px!important;
  }
  .fancy-button{
    height: 30px;
  }
  
  /* Navbar.css */
  .nav__item-1 {
    padding: 15px 30px;
    list-style: none;
}
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: var(--z-fixed);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle{
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.nav__close {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
  margin: 20px 30px;
}
.ok{
  float: right;
}
.nav__item{
  padding: 15px 30px;
  border-bottom: 1px solid #000;
  list-style: none;
}
.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 1150px) {
  .nav__menuu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #fff;
    width: 100%;
    height: 50%;
    transition: right 0.4s;
    z-index: 999;
  }
}
@media screen and (max-width: 300px) {
  .nav__menu {
    padding: 4rem 1rem 2rem;
  }

  .nav__list {
    row-gap: 1rem;
  }

  .nav__link,
  .nav__cta {
    font-size: var(--smaller-font-size);
  }

  .nav__cta {
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 1rem;
    width: auto;
    white-space: nowrap;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.nav__link {
  font-family: "poppins";
  color: #000;
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  font-size: 20px;
}

.nav__link:hover {
  color: var(--first-color);
}

/* .nav__close {
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
} */

.show-menu {
  right: 0;
}
.nav__listt {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
}

.nav__menu_mobile {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: hsla(0, 0%, 6%, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 80%;
  padding: 6rem 3rem 0;
  transition: right 0.4s;
}
@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }
  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}
  
  .circles {
    padding-bottom: 20px;
  }
  .copyright p{
    margin-left: 0px;
    font-family: 'poppins';
  }
  .reel-cont{
    padding: 0px;
    padding-left: 20px;
  }
  .orange-bg1{
    padding: 0px;
  }
  .content h6{
    font-size: 25px;
  }
  .fancy-button{
    margin: 15px 0px 0px 0px;
  }
  .logo1{
    width: 90px;
    margin-right: 8px;
  }
  .btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-size: 12px;
    color: #000!important;
  }
  .btn-container button{
    border: none;
    border-right: 1px solid #FF7C1C;
    width: 25%;
    padding: 6px;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out; /* Smooth transition effect with ease-in-out */
  }
  .sticky-box {
    width: 240px;
    padding: 10px;
    height: 155px;

  }
  .buttonnew1{
    font-size: 12px;
    padding: 4px 8px!important;
  }
  .modal-newletter .down{
    background-color: #FA6B03;
    /* margin-top: 20px; */
    margin: 20px!important;
    font-size: 16px;
    align-items: center;
    color: #fff;
    padding: 13px 25px!important;
    border: 0;
    font-family: 'poppins';
    width: 85%;
    letter-spacing: 1px;
  }
  .modal-newletter button{
    margin-top: 0px!important;
  }
  .form-label input[type="text"],
input[type="email"], 
input[type="tel"] {
  margin: 25px;
}
  .modal-form-label input[type="text"],
input[type="email"], 
input[type="tel"] {
  margin: 25px;
}
.modal-newletter .inp:focus{
  border: none;
}
  .pad{
    padding: 20px 0px!important;
  }
  .modalpopup .h6{
    letter-spacing: 1.5px;
    text-align: left;
    margin: 12px 0px 30px 30px!important;
    color: #000;
    font-family: 'Poppins';
    font-weight: 500;
  }
  .modal-content{
    border-radius: 0px;
    margin: 10px;
  }
  .modal.show .modal-dialog{

    width: 100%!important;
    
    border-radius: 0px!important;
  }
  .modal-enter{
    margin: 20px!important;
  }
  .modal-enter p{
    margin-bottom: 0px!important;
  }
  .modalpopup .h6{
    letter-spacing: 1.5px;
    text-align: left;
    margin:10px 20px;
    font-size: 18px;
    color: #000;
  }
  .modal-enter{
    margin:0px;
  }
  .circle1 {
    position: absolute;
    width: 180px;
    height: 180px;
    background-image: url('./circle22.png');
    background-size: contain;
    background-repeat: none;
    border-radius: 50%;
    transition: transform 0.5s;
  }
  .circle-container1 {
    position: relative;
    width: 180px;
    height: 180px;
    background-size: contain;
    background-repeat: none;
    overflow: hidden;
    border-radius: 50%;
    margin: 25px auto;
  }

  .background-about-stoketext-second::before{
    display: none!important;
  }
  .background-about-stoketext::before{
    display: none!important;
  }
 .orange-banner{
  padding-left: 25px;
}
  /*Animation Arrow*/
  .nav-arrow{
    position: absolute;
    margin-left: 30px;
    bottom: -19px;
  
  }
.orange-col{
  border: 1px solid white;
}
.arrow-div {
  position: relative;
  animation: moveUpDown 4s infinite; /* 2s for up and 2s for down, repeat infinitely */
}

@keyframes moveUpDown {
  0%, 100%{
      top: 88% /* Start and end position */
  }
  50% {
      top: 82%; /* Middle position */
  }
  100%{
      top: 88%;
  }
}

.rose{
background:#FF66CC;
}

.center{
  position: absolute;
  bottom: 2px;
  left: 18px;
}

.circle{
width: 35px;
height: 35px;
border-radius: 50%;
box-shadow: 0px 0px 1px 1px #fff;
}

.pulse {
animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
0% {
  box-shadow: 0 0 0 0px rgba(221, 218, 218, 0.2);
}
100% {
  box-shadow: 0 0 0 5px rgba(221, 218, 218, 0.2);
}
}
  .logo{
    margin-right: 8px;
  }
  .oneee{
    color: #fff;
    padding-top: 10px;
    padding-left: 8px;
    font-size: 14px;
  }
  .onee{
    color: #000;
    font-size: 14px;
    margin-bottom: -10px;
    padding-left: 10px;
    }
  .navbar-expand-lg{
    padding: 25px 15px 20px 32px;
  }
  .banner-caption{
    position: absolute;
    bottom: 10%;
    padding: 0px 30px 0px 40px;
  }
  .rigthul li{
    color: '#fff';
    font-family: "Poppins";
    margin-left: 36px;
    margin-bottom: 15px!important;
    font-size: 17px;
    font-weight: 300;
    }
  .newletter input{
    margin: 18px 0px;
    font-size: 16px;
    width: 100%;
    padding: 15px 30px!important;
  }
  .newletter button{
    font-size: 16px;
    font-weight: 300;
    padding: 12px 25px!important;
    width: 100%;
  }
  .about-stoketext1 .per1 {
    background:url('./smhouse.png');
      position: static;
      width: 40%;
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      margin-top: -20px;
      z-index: 0;
  }
  .img-container-why .per1{
    background:url('./smhouse.png');
    background-repeat: no-repeat;
    margin-top: -22px;
  }
  video {
    width: 100%;
    margin: auto;
    display: block;
    border-radius: 10px;
  }
  
  .footerleft h5{
    font-size: 35px;
  }
  .footerleft h6{
    font-size: 31px;
  }
  footer{
    padding:40px 10px 15px 10px!important;
  }
  .rigthul{
    display: flex;
    flex-direction: column;
  }
  .rigthul li{
    color: '#fff';
    font-family: "Poppins";
    margin-left: 10px;
    margin-bottom: 15px!important;
    font-size: 15px;
  }
  .rigthul1{
    display: flex;
    color: #fff;
    list-style-type: none;
    padding: 0px;
  }
  .rigthul1 li{
  color: '#fff';
  font-family: "Poppins";
  margin: 0px 20px 30px 10px;
  font-size: 17px;
  }
  form{
    padding: 20px 0px 40px 0px;
  }
  footer{
    padding: 15px;
  }
  .img-container-why{
    margin-bottom: 0px;
  }
  .about-stoketext1{
    font-size: 21px;
  }
  .separate{
    height: 104px;
    background-color:#272727 ;
    margin-top: -20%;
  }
  .round-circle{
    border-radius: 100px;
    border: 2px dotted #fff;
    /* margin: auto; */
    height: 200px;
    width: 200px;
    border-radius: 50%; /* Makes the div circular */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-family: Arial, sans-serif; /* Font family */
    font-size: 16px; /* Font size */
    transition: border 6s ease; /* Smooth transition for the border */
  }
  .videocard{
    margin-bottom: 20px;
  }
  .about-stoketext {
  font-size:21px;
  }
  .about-stoketext1{
    color: #fff; /* Set text color to transparent */
    text-align: left;
    font-family: 'Poppins';
    margin: 50px auto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 141.818% */
       color: #fff    /* Add white border stroke */
  }
  .about-stoketext{
    margin: 70px 0px 60px 0px;
  }
  .orange-bg p{
    font-size: 17px;
    margin-bottom: 30px;
  }
  .orange-bg{
    padding: 0px;
  }
  .hero-section{
    background-image: url('./smbanner.png');
  background-size: cover;
    height: 60vh;
  }
  .banner-caption h5{
    width: 100%;
    font-size: 38px;
    line-height: 43px;

  }
  .banner-caption{
    top: 28%;
  }
  .about-stoketext .per{
    background-image: url('./smonepercent.svg');
    margin-top: -40px;
    width: 40%;
  }
  .circleorange , .circleorange1{
    display: none;
  }
  .bg-black{
    padding: 0px;
  }
  .round-circle{
    height: 150px;
    width: 150px;
  }
  .round-circle h6{
    font-size: 20px;
    line-height: 26px;
  }
  .round-circle3{
    width: 150px;
    height: 220px;

  }
  .round-circle2{
    width: 150px;
    height: 200px;
  }
}
.pad{
  padding: 12px 0px 20px 10px;
}
.modal-enter{
  margin: 20px 110px 0px 0px;
}
.modal-enter p{
  font-family: "poppins";
  color: #202020;
}
.modal-footer-line{
  width: 80px;
  height: 3px;
  margin-top: 10px;
  background-color: #FA6B03;
}
.within{
  font-size: 14px;
  font-weight: 300;
}
.sent{
  font-weight: 300;
  font-size: 14px;
}
.modal-enter span{
  font-weight: 400;
  color: #FA6B03;
}
/* .modal-enter span .b{
  color: #8d8d8d;
} */
.modalpopup .h6{
  letter-spacing: 1.5px;
  text-align: left;
  margin: 0px 0px 0px 38px;
  color: #000;
}
.defaultscolor{
background-color: #FFB580!important;
}

.modal-newletter .inp {
  outline: none;
  color: #000;
background-color: #FCFCFC;
  border: none;
  letter-spacing: 20px;
  font-family: 'poppins';
  padding: 12px 25px;
  font-size: 18px;
  letter-spacing: 1px;
  width: 85%; 
  border-radius: 0px;
border: 1.4px dashed #000;
transition: 0.2s ease;
}
.modal-newletter .inp:hover{
  border: 1.4px solid #000;
}
.input:focus{
  outline: none!important;
}
.modal-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.newletter form{
  align-items: start;
}
.modal-newletter button{
  background-color: #FA6B03;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
  color: #fff;
  padding: 13px 25px!important;
  border: 0;
  font-family: 'poppins';
  width: 85%;
  letter-spacing: 1px;
}
.modal-newletter .down{
  background-color: #FA6B03;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
  color: #fff;
  padding: 13px 25px!important;
  border: 0;
  font-family: 'poppins';
  width: 85%;
  letter-spacing: 1px;
}
.modal-forminput{
  margin-top: 40px;
  padding: 0px;
}
.modal-body{
  padding: 0px;
}

.modalpopup h6{
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  font-family: 'poppins';
  font-weight: 500;
  color:#FA6B03;
  margin: 25px auto;

} 
.bgg{
  background: transparent;
}
.modal.show .modal-dialog{
  width: 70%;

  border-radius: 0px!important;
}

.form-group {
  margin-bottom: 0; /* Remove default margin */
}
.form-label-top {
  margin-bottom: 0; /* Remove default bottom margin for label */
  position: relative;
  top: -1rem; /* Adjust label position to touch the top of the input */
}